import React, { useState, useEffect } from "react";

const App = () => {
  // Arrays of images for cycling
  const piideoImages = [
    `${process.env.PUBLIC_URL}/images/img-piideo-1.png`,
    `${process.env.PUBLIC_URL}/images/img-piideo-2.png`,
    `${process.env.PUBLIC_URL}/images/img-piideo-3.png`,
  ];

  const audioZicImages = [
    `${process.env.PUBLIC_URL}/images/audiozic-1.png`,
    `${process.env.PUBLIC_URL}/images/audiozic-2.png`,
    `${process.env.PUBLIC_URL}/images/audiozic-3.png`,
  ];

  const mcknightImages = [
    `${process.env.PUBLIC_URL}/images/mcknight-0.png`,
    `${process.env.PUBLIC_URL}/images/mcknight-1.png`,
    `${process.env.PUBLIC_URL}/images/mcknight-2.png`,
  ];

  // States to keep track of the current image index
  const [piideoIndex, setPiideoIndex] = useState(0);
  const [audioZicIndex, setAudioZicIndex] = useState(0);
  const [mcknightIndex, setMcknightIndex] = useState(0);
  const [fadeMcknight, setFadeMcknight] = useState(true); // For fade transition

  // Cycle through Piideo images every 3 seconds
  useEffect(() => {
    const piideoInterval = setInterval(() => {
      setPiideoIndex((prevIndex) => (prevIndex + 1) % piideoImages.length);
    }, 3000);
    return () => clearInterval(piideoInterval);
  }, [piideoImages.length]);

  // Cycle through Audio Zic images every 3 seconds
  useEffect(() => {
    const audioZicInterval = setInterval(() => {
      setAudioZicIndex((prevIndex) => (prevIndex + 1) % audioZicImages.length);
    }, 3000);
    return () => clearInterval(audioZicInterval);
  }, [audioZicImages.length]);

  // Cycle through McKnight-Man images with a fade transition
  useEffect(() => {
    const mInterval = setInterval(() => {
      setFadeMcknight(false); // start fade out
      setTimeout(() => {
        // after fade out is done, switch image and fade in
        setMcknightIndex(
          (prevIndex) => (prevIndex + 1) % mcknightImages.length
        );
        setFadeMcknight(true); // fade in new image
      }, 500); // 0.5s fade out before changing image
    }, 3000);

    return () => clearInterval(mInterval);
  }, [mcknightImages.length]);

  return (
    <>
      <style>
        {`
          body {
            margin: 0;
            font-family: "Arial", sans-serif;
            background-color: #f9f9f9;
            color: #333;
          }

          nav {
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            position: sticky;
            top: 0;
            z-index: 10;
          }

          nav img {
            height: 40px;
          }

          .nav-links {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
          }

          .nav-links a {
            text-decoration: none;
            margin: 0 1rem;
            color: #333;
            font-weight: 500;
          }

          .nav-links a:hover {
            color: #1a2143;
          }

          /* Hide "Projects" and "About" on mobile */
          @media (max-width: 600px) {
            .nav-link-projects,
            .nav-link-about {
              display: none;
            }

            .nav-links a {
              margin: 0 0.5rem;
              font-size: 0.9rem;
            }
          }

          .hero {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 4rem 1rem;
            text-align: center;
            background: #fff;
          }

          .hero h1 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
            color: #1a2143;
          }

          .hero p {
            font-size: 1.1rem;
            max-width: 600px;
            margin-bottom: 2rem;
            line-height: 1.5;
          }

          .hero a {
            display: inline-block;
            padding: 0.75rem 1.5rem;
            background-color: #1a2143;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s;
            white-space: nowrap; /* Ensures single line on mobile */
          }

          .hero a:hover {
            background-color: #333;
          }

          /* Adjust "View My Work" button on smaller screens */
          @media (max-width: 600px) {
            .hero a {
              font-size: 0.9rem;
              padding: 0.6rem 1rem;
            }
          }

          .section {
            padding: 3rem 1rem;
            max-width: 1200px;
            margin: 0 auto;
          }

          .section h2 {
            text-align: center;
            margin-bottom: 2rem;
            font-size: 2rem;
            color: #1a2143;
          }

          /* Services Section */
          .services {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
          }

          @media (min-width: 768px) {
            .services {
              grid-template-columns: repeat(3, 1fr);
            }
          }

          .service-card {
            background: #fff;
            border-radius: 5px;
            padding: 2rem;
            box-shadow: 0 2px 10px rgba(0,0,0,0.05);
            text-align: center;
          }

          .service-card h3 {
            margin-bottom: 1rem;
            font-size: 1.5rem;
            color: #333;
          }

          .service-card p {
            font-size: 1rem;
            color: #666;
          }

          /* Project Sections */
          .projects-container {
            margin-top: 2rem;
          }

          /* Website Project (McKnight-Man) Layout */
          .website-project {
            margin-bottom: 3rem;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 2px 10px rgba(0,0,0,0.05);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 2rem;
          }

          .website-project .website-image {
            max-width: 100%;
            border-radius: 5px;
            margin-bottom: 1rem;
            height: auto;
            transition: opacity 0.5s ease-in-out; 
          }

          .website-project .website-content h3 {
            font-size: 1.5rem;
            color: #333;
            margin-bottom: 1rem;
          }

          .website-project .website-content p {
            font-size: 1rem;
            color: #666;
            max-width: 600px;
            margin: 0 auto 1.5rem;
            line-height: 1.5;
          }

          .website-project .website-content a {
            display: inline-block;
            padding: 0.5rem 1rem;
            background-color: #1a2143;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s;
          }

          .website-project .website-content a:hover {
            background-color: #333;
          }

          /* Projects Grid (Apps) */
          .projects-grid {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
          }

          @media (min-width: 768px) {
            .projects-grid {
              grid-template-columns: repeat(2, 1fr);
            }
          }

          .project-card {
            background: #fff;
            border-radius: 5px;
            padding: 1rem;
            box-shadow: 0 2px 10px rgba(0,0,0,0.05);
            transition: transform 0.3s;
            text-align: center;
          }

          .project-card:hover {
            transform: translateY(-5px);
          }

          .project-card img {
            border-radius: 5px;
            margin-bottom: 1rem;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
          }

          /* Making app images smaller */
          .app-image {
            max-height: 200px;
            width: auto;
            object-fit: contain;
          }

          .project-card h4 {
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
            color: #333;
          }

          .project-card p {
            font-size: 1rem;
            color: #666;
            margin-bottom: 1rem;
          }

          .project-card a {
            display: inline-block;
            padding: 0.5rem 1rem;
            background-color: #1a2143;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s;
          }

          .project-card a:hover {
            background-color: #333;
          }

          /* Disabled link styling */
          .disabled-link {
            pointer-events: none; 
            opacity: 0.5; 
            background-color: #ccc !important;
          }

          /* About Section */
          .about {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }

          .about img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            object-fit: cover;
            margin-bottom: 1rem;
          }

          .about p {
            max-width: 600px;
            line-height: 1.6;
            color: #666;
          }

          /* Footer */
          footer {
            background: #1a2143;
            color: #fff;
            text-align: center;
            padding: 2rem 1rem;
          }

          footer p {
            margin: 0.5rem 0;
            color: #ccc;
            font-size: 0.9rem;
          }

          .social-links a {
            margin: 0 0.5rem;
            color: #fff;
            text-decoration: none;
            font-size: 1.2rem;
          }

          .social-links a:hover {
            color: #4da6ff;
          }
        `}
      </style>

      {/* NAVBAR */}
      <nav>
        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Logo" />
        <div className="nav-links">
          <a href="#services" className="nav-link-services">
            Services
          </a>
          <a href="#projects" className="nav-link-projects">
            Projects
          </a>
          <a href="#about" className="nav-link-about">
            About
          </a>
          <a href="#contact" className="nav-link-contact">
            Contact
          </a>
        </div>
      </nav>

      {/* HERO SECTION */}
      <section className="hero">
        <h1>Building Digital Solutions</h1>
        <p>
          We transform ideas into reality, crafting web and mobile experiences
          that engage users and drive results.
        </p>
        <a href="#projects">View My Work</a>
      </section>

      {/* SERVICES SECTION */}
      <section className="section" id="services">
        <h2>Services</h2>
        <div className="services">
          <div className="service-card">
            <h3>Mobile App Development</h3>
            <p>
              Cross-platform mobile apps built with Flutter/Dart, delivering
              seamless experiences on Android and iOS.
            </p>
          </div>
          <div className="service-card">
            <h3>UI/UX Design</h3>
            <p>
              Intuitive, user-centric interfaces designed with a keen eye for
              aesthetics and usability.
            </p>
          </div>
          <div className="service-card">
            <h3>Web Development</h3>
            <p>
              Scalable, responsive web applications built with React, Node.js,
              and modern frameworks.
            </p>
          </div>
        </div>
      </section>

      {/* PROJECTS SECTION */}
      <section className="section" id="projects">
        <h2>Recently Uploaded</h2>

        {/* McKnight-Man as a website (top because it's the most recent) */}
        <div className="website-project">
          {/* Display the currently selected McKnight image with fade transition */}
          <img
            src={mcknightImages[mcknightIndex]}
            alt="McKnight-Man"
            className="website-image"
            style={{
              opacity: fadeMcknight ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
            }}
          />
          <div className="website-content">
            <h3>McKnight-Man</h3>
            <p>
              Bespoke fashion with superb tailoring and an infinite catalogue of
              designs.
            </p>
            <a
              href="https://www.mcknight-man.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Website
            </a>
          </div>
        </div>

        {/* PIIDEO & Audio Zic as apps (below the website) */}
        <div className="projects-container">
          <div className="projects-grid">
            {/* PIIDEO Project */}
            <div className="project-card">
              <img
                src={piideoImages[piideoIndex]}
                alt="Piideo"
                className="app-image"
              />
              <h4>PIIDEO</h4>
              <p>
                A recently launched fashion exploration app, celebrating African
                fashion and creativity.
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.jaysmart.piideo"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Project
              </a>
            </div>

            {/* Audio Zic Project (Disabled Link) */}
            <div className="project-card">
              <img
                src={audioZicImages[audioZicIndex]}
                alt="Audio Zic"
                className="app-image"
              />
              <h4>Audio Zic</h4>
              <p>Showcasing custom-designed audio headsets and technology.</p>
              <a className="disabled-link">View Project</a>
            </div>
          </div>
        </div>
      </section>

      {/* ABOUT SECTION */}
      <section className="section" id="about">
        <h2>About Me</h2>
        <div className="about">
          {/* Uncomment and use if you have a profile image */}
          {/* <img
            src={process.env.PUBLIC_URL + "/images/profile.png"}
            alt="Profile"
          /> */}
          <p>
            Passionate about creating experiences that blend aesthetics and
            functionality, I have been working in tech since 2018. My approach
            combines creativity, expertise, and a deep understanding of clients'
            needs. Ready to bring your vision to life? Let’s build something
            extraordinary.
          </p>
        </div>
      </section>

      {/* CONTACT SECTION */}
      <section className="section" id="contact">
        <h2>Contact</h2>
        <p style={{ textAlign: "center", marginBottom: "1rem" }}>
          Interested in working together? Get in touch!
        </p>
        <div style={{ textAlign: "center" }}>
          <a
            href="mailto:developer@jaysmart.dev"
            style={{
              background: "#1a2143",
              color: "#fff",
              padding: "0.75rem 1.5rem",
              textDecoration: "none",
              borderRadius: "5px",
            }}
          >
            developer@jaysmart.dev
          </a>
        </div>
      </section>

      {/* FOOTER */}
      <footer>
        <p>Connect with me on social media</p>
        <div className="social-links">
          <a
            href="https://wa.me/message/IWKFOX6HVTO7C1"
            target="_blank"
            rel="noopener noreferrer"
          >
            WhatsApp
          </a>
          <a
            href="https://www.linkedin.com/in/anutaajovwoke"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a
            href="https://github.com/JaySmart001"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
        </div>
        <p style={{ marginTop: "1rem" }}>
          © {new Date().getFullYear()} JaySmart Technologies
        </p>
      </footer>
    </>
  );
};

export default App;
